import { ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/browser'

export function logException(
  error: ApolloError | Error | string,
  options: { extras?: Record<string, unknown> } = {}
) {
  if (typeof error !== 'string' && 'graphQLErrors' in error) {
    error.graphQLErrors = error.graphQLErrors.filter(
      (error) =>
        // @ts-expect-error custom field
        error.type !== 'validation_error' &&
        // Workaround for incorrect BE type
        !error.message.includes('Cannot return null for non-nullable field')
    )

    // Escape hatch if all error were filtered out
    if (
      !error.graphQLErrors.length &&
      !error.networkError &&
      !error.clientErrors.length &&
      !error.protocolErrors.length
    )
      return
  }

  Sentry.withScope((scope) => {
    if (options.extras) {
      Object.entries(options.extras).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
    }

    Sentry.captureException(error)
  })

  if (import.meta.env.MODE !== 'test') console.error(error)
}
