import Toast from 'Toast'
import { logException } from 'utils/exceptionLogger'
import http from 'utils/http'

import replaceInterpolationSymbols from './replaceInterpolationSymbols'

const translations = http
  .get<Record<string, string>>('/translations?locale=' + window.locale_name)
  .then(({ data }) =>
    Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        replaceInterpolationSymbols(value, true),
      ])
    )
  )
  .catch((e) => {
    logException(e as Error)

    Toast.error(
      'Something went wrong while fetching translations. Please try again later.'
    )

    return Promise.resolve(null)
  })

export default translations
