import axios, { CreateAxiosDefaults } from 'axios'

const axiosConfig: CreateAxiosDefaults = {
  baseURL: window.ctxpre,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
  method: 'post',
  responseType: 'json',
  transformRequest: [
    function (data: unknown) {
      if (typeof data !== 'string') {
        try {
          data = JSON.stringify(data)
        } catch (e) {
          console.log(e)
        }
      }

      return data
    },
  ],
  transformResponse: [
    function (data) {
      return data
    },
  ],
  url: '/',
  withCredentials: true,
}

const http = axios.create(axiosConfig)

http.interceptors.response.use((response) => {
  // IE11 fix
  if (
    response.config.responseType === 'json' &&
    typeof response.data === 'string'
  ) {
    try {
      response.data = JSON.parse(response.data)
    } catch {}
  }

  return response
})

export default http
