import React, { ReactNode, use, useCallback } from 'react'
import { IntlConfig, IntlProvider } from 'react-intl'

import Toast from 'Toast'

import { formats } from './constants'
import translations from './translations'
import useDebugMessage from './useDebugMessage'

export type Props = {
  children: ReactNode
  locale?: string
}

// We allow defaultMessage to only be string, and both defaultMessage and id are required
export type ValidMessageDescriptor = { defaultMessage: string; id: string }

function LanguageProvider({ children, locale = window.localeName }: Props) {
  const data = use(translations)

  const { transformedMessages } = useDebugMessage(data ?? {})

  const onError = useCallback<NonNullable<IntlConfig['onError']>>((error) => {
    if (import.meta.env.DEV) {
      Toast.warning(error.message)
    }
  }, [])

  const onWarn = useCallback<NonNullable<IntlConfig['onWarn']>>((warning) => {
    if (import.meta.env.DEV) {
      Toast.warning(warning)
    }
  }, [])

  return (
    <IntlProvider
      formats={formats}
      locale={locale}
      messages={transformedMessages}
      onError={onError}
      onWarn={onWarn}
    >
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider
