import { useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

function useDebugMessage(messages: Record<string, string>) {
  const [showDevMessages, setShowDevMessages] = useState(false)

  useHotkeys(
    'meta+d',
    () => {
      setShowDevMessages((prev) => !prev)
    },
    { enabled: !!window.CONST?.me?.isSupervisor, preventDefault: true }
  )

  const transformedMessages = useMemo(() => {
    if (showDevMessages) {
      return Object.keys(messages).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key,
        }),
        {}
      )
    }

    return messages
  }, [messages, showDevMessages])

  return { transformedMessages }
}

export default useDebugMessage
